import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-palmdale-california.png'
import image0 from "../../images/cities/scale-model-of-public-art-\"walk-on-words\"-in-palmdale-california.png"
import image1 from "../../images/cities/scale-model-of-public-art-\"floating-dog-and-flying-cat\"-in-palmdale-california.png"
import image2 from "../../images/cities/scale-model-of-public-art-\"facing-the-sun\"-in-palmdale-california.png"
import image3 from "../../images/cities/scale-model-of-elyze-clifford-interpretive-center-in-palmdale-california.png"
import image4 from "../../images/cities/scale-model-of-prime-desert-woodland-preserve-in-palmdale-california.png"
import image5 from "../../images/cities/scale-model-of-marie-kerr-park-in-palmdale-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Palmdale'
            state='California'
            image={image}
            lat='34.5794343'
            lon='-118.11646129999997'
            attractions={ [{"name": "Public Art \"Walk On Words\"", "vicinity": "38315 9th St E, Palmdale", "types": ["point_of_interest", "establishment"], "lat": 34.5780512, "lng": -118.11515830000002}, {"name": "Public Art \"Floating Dog and Flying Cat\"", "vicinity": "Palmdale", "types": ["point_of_interest", "establishment"], "lat": 34.5831233, "lng": -118.11666580000002}, {"name": "Public Art \"Facing the Sun\"", "vicinity": "Unnamed Road, Palmdale", "types": ["point_of_interest", "establishment"], "lat": 34.5771843, "lng": -118.11527860000001}, {"name": "ELYZE CLIFFORD INTERPRETIVE CENTER", "vicinity": "43201 35th St W, Lancaster", "types": ["park", "point_of_interest", "establishment"], "lat": 34.6676813, "lng": -118.19370979999997}, {"name": "Prime Desert Woodland Preserve", "vicinity": "43201 35th St W, Lancaster", "types": ["park", "point_of_interest", "establishment"], "lat": 34.6676449, "lng": -118.19296930000002}, {"name": "Marie Kerr Park", "vicinity": "39700 30th St W, Palmdale", "types": ["park", "point_of_interest", "establishment"], "lat": 34.6036659, "lng": -118.18204100000003}] }
            attractionImages={ {"Public Art \"Walk On Words\"":image0,"Public Art \"Floating Dog and Flying Cat\"":image1,"Public Art \"Facing the Sun\"":image2,"ELYZE CLIFFORD INTERPRETIVE CENTER":image3,"Prime Desert Woodland Preserve":image4,"Marie Kerr Park":image5,} }
       />);
  }
}